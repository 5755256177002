import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";

export default function ButtonWithScale({
	text,
	to,
	className,
	onClick,
	children,
	disabled,
	style,
	myRef,
	hideLink = false,
}) {
	const { search, pathname } = useLocation();

	return (
		<button
			ref={myRef}
			style={style}
			className={className ? "buttonWithScale " + className : "buttonWithScale"}
			onClick={onClick}
			disabled={disabled}
		>
			{hideLink ? (
				text || children
			) : (
				<Link to={to || `${pathname}${search}`}>
					{text || children ? text || children : "ButtonWithScale"}
				</Link>
			)}
		</button>
	);
}
