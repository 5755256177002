import { prod_donain_name } from "config/defaultData";
import { isEqual } from "lodash";

const AIRTABLE_TOKEN_KEY =
	"patxolmYIKYkoClWc.3f24cfc872ebaf24a7f80698147a9db37ec53af0e5f06f7825457261d21249a3";

const AIRTABLE_ID = "appy5hYKsORrKNu04";
const AIRTABLE_NAME_MR_WRAPPER_MODEL_LIST = "tblApKojYDoe06r4A";
const AIRTABLE_NAME_PROMO_CODE = "tblHRuHc95LqOmZlw";
// https://airtable.com/appy5hYKsORrKNu04/tblApKojYDoe06r4A/viwtpl3oyx8ocZ22k?blocks=hide
class AirtableService {
	constructor(apiKey, baseId, tableName) {
		this.apiKey = apiKey;
		this.baseId = baseId;
		this.tableName = tableName;
		this.baseURL = `https://api.airtable.com/v0/${this.baseId}/${this.tableName}`;
	}

	// Get headers for API calls
	get headers() {
		return {
			Authorization: `Bearer ${this.apiKey}`,
			"Content-Type": "application/json",
		};
	}

	// Get all records
	async getAllRecords() {
		try {
			const response = await fetch(this.baseURL, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			return data.records;
		} catch (error) {
			console.error("Error fetching records:", error);
			throw error;
		}
	}

	async getAllRecordsWithLimit() {
		let allRecords = [];
		let offset = null;

		do {
			const url = `https://api.airtable.com/v0/${this.baseId}/${
				this.tableName
			}${offset ? `?offset=${offset}` : ""}`;
			const response = await fetch(url, {
				headers: this.headers,
			});
			const data = await response.json();
			allRecords = [...allRecords, ...data.records];
			offset = data.offset;
		} while (offset);

		return allRecords;
	}

	// Get a single record by ID
	async getRecord(recordId) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error fetching record:", error);
			throw error;
		}
	}

	// Create a new record
	async createRecord(fields) {
		try {
			const response = await fetch(this.baseURL, {
				method: "POST",
				headers: this.headers,
				body: JSON.stringify({ fields: fields }),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error creating record:", error);
			throw error;
		}
	}

	// Update a record
	async updateRecord(recordId, fields) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "PATCH",
				headers: this.headers,
				body: JSON.stringify({ fields }),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error updating record:", error);
			throw error;
		}
	}

	// Delete a record
	async deleteRecord(recordId) {
		try {
			const response = await fetch(`${this.baseURL}/${recordId}`, {
				method: "DELETE",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			return await response.json();
		} catch (error) {
			console.error("Error deleting record:", error);
			throw error;
		}
	}

	// Query records with formula
	async queryRecords(filterFormula) {
		try {
			const params = new URLSearchParams({
				filterByFormula: filterFormula,
			});

			const response = await fetch(`${this.baseURL}?${params}`, {
				method: "GET",
				headers: this.headers,
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			return data.records;
		} catch (error) {
			console.error("Error querying records:", error);
			throw error;
		}
	}
}

export const mrWrapperModelList = new AirtableService(
	AIRTABLE_TOKEN_KEY,
	AIRTABLE_ID,
	AIRTABLE_NAME_MR_WRAPPER_MODEL_LIST
);

export const promoCodeAirtable = new AirtableService(
	AIRTABLE_TOKEN_KEY,
	AIRTABLE_ID,
	AIRTABLE_NAME_PROMO_CODE
);

export const getOrderAirtable = () => {
	const host = window.location.host;

	const result = isEqual(prod_donain_name, host);
	if (result) {
		return new AirtableService(
			"patsZkD3EtG2ZRAFF.8709c93f17f1362b949ab5e0d09edd1920d877bc7664584e3596a933f1698ed2",
			"appB9VowjFinOeA4S",
			"Mr Wrapper Online Orders"
		);
	} else {
		return new AirtableService(
			"patjnV34xnyFbY9y3.217abde706cfcdaa8893b5339a07c672debd898249bb664b71fb15068f8e498d",
			"applpFwu5IYNtqAGF",
			"Mr Wrapper Online Orders"
		);
	}
};

//patJDdaWdTOpkvwnn.e78000f7e7702a20742e1c2458e88bdcf20bb83ff6b8295243cc526e3dab965c
